import {
  flagsAreLoadingAtom,
  userLoginStatusAtom,
} from '@/State/global/global.store';
import { useAtomValue } from 'jotai';
import { currencySymbols } from 'data/currency';
import { useCurrency } from '@/Lib/hooks/useCurrency';

type Props = {
  discount: number;
  price: number;
  discountedPrice: number;
};

const Price = ({ discount, price, discountedPrice }: Props) => {
  const flagsAreLoading = useAtomValue(flagsAreLoadingAtom);
  const { currency, currencyShouldBeShown } = useCurrency();
  // const isUserLoggedIn = useAtomValue(userLoginStatusAtom);
  const isUserLoggedIn = true;

  return (
    <>
      {isUserLoggedIn === true && !flagsAreLoading && (
        <div className="text-slate-600 text-sm leading-5">
          {discount ? (
            <p>
              <span className="line-through text-dark-800 text-base mr-1 font-semibold">
                {price?.toLocaleString()}
              </span>
              <span className="text-red-900 font-semibold text-sm">
                {currencySymbols[currency]}
                {discountedPrice?.toLocaleString()}
                {currencyShouldBeShown ? (
                  <span className="text-xs relative -top-px ml-0.5">
                    ({currency})
                  </span>
                ) : null}
              </span>
            </p>
          ) : null}
          {!discount ? (
            <p className="text-dark-800 text-base font-semibold">
              {currencySymbols[currency]}
              {price?.toLocaleString()}
              {currencyShouldBeShown ? (
                <span className="text-xs relative -top-px ml-0.5">
                  ({currency})
                </span>
              ) : null}
            </p>
          ) : null}
        </div>
      )}
      {/* {isUserLoggedIn === false && !flagsAreLoading && (
        <p className="text-xs font-semibold text-dark-700 underline mt-1.5">
          See Inclusions & Pricing
        </p>
      )} */}
      {(isUserLoggedIn === null || flagsAreLoading) && (
        <div className="my-1 h-5 w-12 bg-light-900  animate-pulse "></div>
      )}
    </>
  );
};

export { Price };
